<template>
    <div class="main">
        <MenuApp></MenuApp>
        <div class="winners-container">
            <div class="search-bar" v-if="winners && winners.length > 0">
                <input type="text" placeholder="Email search" v-model="search" />
            </div>
            <template v-if="winners && winners.length > 0">
                <div class="table-winners">
                    <table>
                        <thead>
                            <tr>
                                <th>Lastname</th>
                                <th>Firstname</th>
                                <th>Email</th>
                                <th>City</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in winnersFilter" :key="idx">
                                <td>{{ item.lastname }}</td>
                                <td>{{ item.firstname }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.city }}</td>
                                <td>{{ getPrice(item.pw_price_type) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-else>
                <p class="no-winners">No winner yet!</p>
            </template>
        </div>
    </div>
</template>

<script>
import MenuApp from './../components/MenuApp'
import axios from 'axios'
import store from './../store'

export default {
    name : 'Winners',
    components : {
        MenuApp
    },
    data() {
        return {
            search : null,
            winners : []
        }
    },
    mounted() {
        let config = {
            validateStatus: () => true,
        };
        
        config.headers = { Authorization: `Bearer ${store.state.JWT_TOKEN}` }
        
        let vm = this

        axios
            .get(store.getters.getURL +'codes/winners', config)
            .then(function (response) {
                if(response.status === 200) {
                    vm.winners = response.data.winners
                } else {
                    store.dispatch('addNotif', {type : 2, content : response.data.message})
                }
                })
            .catch((res) => {
                store.dispatch('addNotif', {type : 2, content : res})
            });
    },
    computed : {
        winnersFilter() {
            if(this.search) {
                return this.winners.filter(e => e.email.toLowerCase().includes(this.search.toLowerCase()))
            }else{
                return this.winners
            }
        }
    },
    methods : {
        getPrice(type) {
            return type === 1 ? 'Small price' : 'Big price'
        }
    }

}
</script>

<style scoped>
    .winners-container {
        margin:40px auto;
        max-width: 90%;
        width:1200px;
    }

    .search-bar {
        display:flex;
        justify-content: flex-end;
    }

    .search-bar input:focus {
        outline: none;
    }

    .search-bar input {
        border: 1px solid #d6d6d6;
        width: 250px;
        padding: 10px 15px;
        border-radius: 20px;
        font-family: 'Arimo';
    }

    p.no-winners {
        font-family: 'Arimo';
        color:#a4a5a6; 
        text-align: center;
    }

    .table-winners {
        margin-top:20px;
        border:1px solid #a4a5a6;
        border-radius:10px;
    }

</style>