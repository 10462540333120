<template>
    <div class="menu-container">
        <router-link to="/winners">Winners</router-link>
        <router-link to="/codes">Codes</router-link>
        <router-link to="/parameters">Parameters</router-link>
    </div>
</template>

<script>
export default {
    name : 'MenuApp'
}
</script>

<style scoped>
    .menu-container {
        margin-top:20px;
        display: flex;
        justify-content: center;
    }

    .menu-container a {
        color:#335c68;
        padding:5px 10px;
        font-size:20px;
        font-family: 'Arimo_bold';
        text-decoration: none;
    }

     .menu-container a:nth-child(2) {
         border-left:1px solid #335c68;
         border-right:1px solid #335c68;
     }

     .menu-container a:hover, .menu-container a.router-link-exact-active {
         color:#ef7941;
     }
</style>